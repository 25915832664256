import React, { useEffect, useState } from "react";
import api from "./Api";
import "../style/App.css";
import ReadMoreReadLess from "./ReadMoreReadLess";

export default function Retreats() {
  const [retreats, setRetreats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatDateRange(starting_date, ending_date) {
    // Parse the dates
    const startDate = new Date(starting_date);
    const endDate = new Date(ending_date);

    // Define month and day formatters in Swedish
    const dayFormatter = new Intl.DateTimeFormat("sv", { weekday: "short" });
    const dateFormatter = new Intl.DateTimeFormat("sv", { day: "numeric" });
    const monthFormatter = new Intl.DateTimeFormat("sv", { month: "long" });

    // Extract the components
    const startDay = capitalizeFirstLetter(dayFormatter.format(startDate));
    const startDayNumber = dateFormatter.format(startDate);
    const endDay = capitalizeFirstLetter(dayFormatter.format(endDate));
    const endDayNumber = dateFormatter.format(endDate);
    const month = capitalizeFirstLetter(monthFormatter.format(startDate)); // assuming the same month for start and end

    // Build and return the string
    return `${startDay} ${startDayNumber} - ${endDay} ${endDayNumber} ${month}`;
  }

  useEffect(() => {
    const getRetreats = async () => {
      try {
        const response = await api.get("/retreats/all");
        if (response.status !== 200) {
          setError("Error fetching data");
          return;
        }
        setRetreats(response.data.retreats);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error in get Retreats", error.message);
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    getRetreats();
  }, []);

  console.log(retreats);
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="retreats__container__page">
      <div className="retreats__grid__page">
        {retreats?.map((val, key) => {
          const dateRange = formatDateRange(val.starting_date, val.ending_date);
          let availability;

          if (val.availability > 10) {
            availability = "Lediga platser";
          } else if (val.availability <= 10 && val.availability > 0) {
            // Explicitly including the equal to 10 condition here
            availability = "Få platser kvar";
          } else {
            availability = "Fullt";
          }
          return (
            <div className="retreats__card__page">
              <div className="img__frame__page">
                <img src={val.image} />
              </div>
              <div className="first__content">
                <h2>{val.name}</h2>
                <p className="date">{dateRange}</p>
                <p>
                  {" "}
                  <strong>{val.price} SEK/person</strong>
                </p>
                <p className="availability">{availability}</p>

                <div className="card__line"></div>
                <div className="container">
                  <ul>
                    <li>
                      <a
                        className="animated-arrow"
                        onClick={(e) => {
                          window.location = `/retreat?retreat=${val.id}`;
                          e.preventDefault();
                        }}
                      >
                        <span className="the-arrow -left">
                          <span className="shaft"></span>
                        </span>
                        <span className="main">
                          <span className="text">Boka</span>
                          <span className="the-arrow -right">
                            <span className="shaft"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="second__content__page">
                <ReadMoreReadLess>{val.description}</ReadMoreReadLess>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
