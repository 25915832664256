import React, { useEffect, useState, useRef } from "react";
import api from "./Api";
import "../style/App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const STRIPE_KEY_DEV =
  "pk_test_51O6TAlHLKd711KOa3vohQ3tIAJLNbwXDQ9wtUAWLOQpNQGydz9AKbxk4Q6Z4XVvAZS099SqtB9xzqfD9PfDn0IcW00gPHntAYc";
const STRIPE_KEY_PROD =
  "pk_live_51O6TAlHLKd711KOaxzfRvkUTsgcWjjnw7XRejQz0HjrIuliLrcFK6O9KyRigrDdvu4lsT2RbZckYYzDXVNLiaDa000DGM7Q7Om";

// Determine the environment and use the appropriate key
const stripePublicKey =
  process.env.NODE_ENV === "development" ? STRIPE_KEY_DEV : STRIPE_KEY_PROD;

// Initialize Stripe
const stripe = loadStripe(stripePublicKey);

export default function Package() {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [clientSecret, setClientSecret] = useState();
  const [customer, setCustomer] = useState();
  const [news, setNews] = useState(false);
  const paymentE = useRef();

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!customer?.name) {
      formIsValid = false;
      errors["name"] = "Namn krävs.";
    }

    if (!customer?.email) {
      formIsValid = false;
      errors["email"] = "Email krävs.";
    }

    if (!customer?.tel) {
      formIsValid = false;
      errors["tel"] = "Tel krävs.";
    }

    if (!customer?.participants) {
      formIsValid = false;
      errors["participants"] = "Antal deltagare krävs.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;

    setCustomer((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const handleNewsCheckbox = () => {
    setNews((prevInfo) => ({
      ...prevInfo,
      wantNews: !prevInfo.wantNews,
    }));
  };

  useEffect(() => {
    if (clientSecret && paymentE.current) {
      const elementRect = paymentE.current.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle =
        absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;
      window.scrollTo({ top: middle, behavior: "smooth" });
    }
  }, [clientSecret]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("packages");

    const getPackage = async () => {
      try {
        const response = await api.get("/packages/one", { params: { id } });
        if (response.status !== 200) {
          console.log("error, status code not 200");
          return;
        }
        setPackages(response.data);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error in get Retreats", error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    getPackage();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(customer);
  const piCreate = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await api.post("/pi/packages/create", {
        customer,
        packages,
      });
      if (response.status !== 200) {
        console.log("Response status not 200");
        return;
      }
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.log("Some error creating paymentIntent", error.message);
    }
  };

  return (
    <div className="retreats__container__page">
      <div className="retreats__grid__page">
        {packages?.map((val, key) => {
          return (
            <div key={key} className="retreats__card__page">
              <div className="img__frame__page">
                <img src={val.img} />
              </div>
              <div className="first__content">
                <h2>{val.name}</h2>
                <p>
                  {" "}
                  <strong>{val.price} SEK/person</strong>
                </p>

                <div className="card__line"></div>
              </div>
              <div className="second__content__page">
                {val.desc}
                <div className="group">
                  <input
                    className="subscription--name"
                    type="text"
                    name="name"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Namn</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="text"
                    name="email"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Email</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="text"
                    name="tel"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Tel</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="number"
                    min={1}
                    max={val.availability}
                    name="participants"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Antal</label>
                </div>
                <div className="group">
                  <textarea
                    className="subscription--interest"
                    type="text"
                    name="comment"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Kommentar</label>
                </div>
                {customer?.participants && (
                  <div className="cart">
                    <h5>Din total:</h5>
                    {val.price * customer?.participants} SEK
                  </div>
                )}
                {!clientSecret && (
                  <div className="container">
                    {Object.keys(errors).length > 0 && (
                      <div className="error-messages">
                        <ul>
                          {Object.values(errors).map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                        </ul>
                      </div>
                    )}
                    <ul>
                      <li>
                        <a
                          className="animated-arrow"
                          onClick={(e) => {
                            e.preventDefault();
                            piCreate();
                          }}
                        >
                          <span className="the-arrow -left">
                            <span className="shaft"></span>
                          </span>
                          <span className="main">
                            <span className="text">Välj betalmetod</span>
                            <span className="the-arrow -right">
                              <span className="shaft"></span>
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          );
        })}

        {clientSecret && (
          <div className="stripe__form" ref={paymentE}>
            <Elements options={{ clientSecret }} stripe={stripe}>
              <CheckoutForm purchase={"package"} />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
}
