import React, { useEffect } from "react";
import "../style/App.css";
import Retreats from "../modules/Retreats";
import Footer from "../modules/Footer";
import { useLocation } from "react-router-dom";

export default function RetreatsPage() {
  return (
    <div className="retreats__page__container">
      <div className="search__div">
        <img
          className="retreats__hero"
          src="https://res.cloudinary.com/dh8p6vqik/image/upload/v1699392950/samuel__hale_fdb0eu.jpg"
          alt="samuel background peace"
        />
        <div className="search__input">
          <h2>Hitta ditt retreat.</h2>
          {/* <p>HITTA RETREAT</p>
          <div className="input-wrapper">
            <input type="text" />
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/VisualEditor_-_Icon_-_Search.svg/120px-VisualEditor_-_Icon_-_Search.svg.png"
              alt="Search icon"
              className="search-icon"
            />
          </div> */}
        </div>
      </div>
      <Retreats />
      <Footer />
    </div>
  );
}
