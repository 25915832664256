import axios from "axios";

const baseUrl =
  window.location.origin === "http://localhost:3000"
    ? "http://localhost:3001"
    : "https://samsvard.com";

const api = axios.create({
  baseURL: `${baseUrl}/api`,
  withCredentials: true,
});

export default api;
