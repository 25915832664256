import "../style/App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "../modules/ScrollToTop";

import NavBar from "../modules/NavBar";
import Home from "../pages/Home";
import Retreats from "../pages/Retreats";
import RetreatPage from "../pages/Retreat";
import About from "../pages/About";
import Checkout from "../pages/Checkout";
import CheckoutPackage from "../pages/CheckoutPackage";
import Package from "../modules/Package";
import Policy from "../pages/Policy";

export default function Routing() {
  return (
    <Router>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/retreats" element={<Retreats />} />
        <Route path="/retreat" element={<RetreatPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkoutPackage" element={<CheckoutPackage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/packages" element={<Package />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </Router>
  );
}
