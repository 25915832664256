import React from "react";

import "../style/App.css";
import Retreat from "../modules/Retreat";
import Footer from "../modules/Footer";

export default function RetreatPage() {
  return (
    <div className="retreat__page__container">
      <Retreat />
      <Footer />
    </div>
  );
}
