import React, { useState, useEffect } from "react";
import "../style/Navbar.css";
import api from "../modules/Api";

const Footer = () => {
  const [requestError, setRequestError] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [subscription, setSubscription] = useState({});
  const [displayError, setDisplayError] = useState(false);

  useEffect(() => {
    let timer;
    if (requestError || errorMessage) {
      setDisplayError(true);
      timer = setTimeout(() => {
        setDisplayError(false);
        setRequestError(null); // Clear requestError if needed
        setErrorMessage(null); // Clear errorMessage if needed
      }, 3000); // 3 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }, [requestError, errorMessage]);

  const handleSubscription = (e) => {
    const element = e.target.name;
    const value = e.target.value;

    setSubscription({
      ...subscription,
      [element]: value,
    });
  };

  const createSubscription = async () => {
    try {
      const response = await api.post("/customer/create", {
        customer: subscription,
      });

      if (response.status !== 200) {
        console.log("error");
        return;
      }
      showFriend("friend__success");
    } catch (error) {
      setRequestError(error.response.data.text);
    }
  };
  console.log(subscription);
  const showFriend = (val) => {
    const stripeDiv = document.querySelector(`.${val}`);
    stripeDiv.classList.remove("hidden");
  };
  const hideFriend = (val) => {
    const stripeDiv = document.querySelector(`.${val}`);
    stripeDiv.classList.add("hidden");
  };

  return (
    <div className="footer__div">
      <div className="footer__form">
        <h2>HÅLL DIG UPPDATERAD</h2>
        <p>
          Samuel utvecklar sina kunskaper hela tiden - se till att hänga med! Är
          du intresserad av <strong>privatsessions </strong>eller{" "}
          <strong>sessions för företag</strong> går det självklart bra att
          skriva det i textfältet nedan också. Vill du skräddarsy lösningar
          enbart för dig? Skicka förfrågan nedan så tar Samuel kontakt med dig.
        </p>
        <form className="footer__input">
          <div className="group">
            <input
              className="subscription--name"
              type="text"
              name="name"
              onChange={handleSubscription}
              required="required"
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Namn</label>
          </div>
          <div className="group">
            <input
              className="subscription--email"
              type="text"
              name="email"
              onChange={handleSubscription}
              required="required"
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Email</label>
          </div>
          <div className="group subscription__input--interest">
            <textarea
              className="subscription--interest"
              type="text"
              name="interest"
              maxLength="400"
              onChange={handleSubscription}
              required="required"
            />
            <span className="highlight"></span>
            <span className="bar"></span>
            <label>Interesserad av</label>
            {displayError && errorMessage && (
              <small style={{ color: "#db4343" }}>{errorMessage}</small>
            )}
          </div>

          <div className="subscription--btn">
            {displayError && requestError && (
              <div className="error">
                <p>{requestError}</p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = "/";
                  }}
                >
                  Ladda om sidan
                </button>
              </div>
            )}
          </div>
        </form>

        <div className="container">
          <ul>
            <li>
              <a
                className="animated-arrow"
                onClick={(e) => {
                  e.preventDefault();
                  let error = "";
                  const emailRegex =
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

                  if (!subscription.name) {
                    error = "Namn krävs.";
                  } else if (!subscription.email) {
                    error = "Email krävs.";
                  } else if (!emailRegex.test(subscription.email)) {
                    error = "Ogiltig e-postadress.";
                  } else if (!subscription.interest) {
                    error = "Beskriv vad du gillar";
                  }
                  if (error) {
                    setErrorMessage(error);
                    return;
                  } else {
                    createSubscription();
                  }
                }}
              >
                <span className="the-arrow -left">
                  <span className="shaft"></span>
                </span>
                <span className="main">
                  <span className="text">Skicka</span>
                  <span className="the-arrow -right">
                    <span className="shaft"></span>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer__content">
        <div>
          <h3>CENTER</h3>
          <a href="https://hale.center/sv/blogs/guides/samuel-svard">
            HALE
          </a>{" "}
          <br />
          <a href="https://www.stockholmsaltspa.se/nyhet-saltyoga-breathwork/">
            Sthlm Saltspa
          </a>{" "}
        </div>
        <div>
          <h3>SOCIALS</h3>
          <div className="footer__social">
            <img
              className="footer__social__icon"
              onClick={(e) => {
                e.preventDefault();
                window.location = "https://www.instagram.com/samsvard";
              }}
              href="https://www.instagram.com/samsvard"
              src={`${process.env.PUBLIC_URL}/instagram.png`}
              alt="Description"
            />
            <img
              className="footer__social__icon"
              onClick={(e) => {
                e.preventDefault();
                window.location = "https://www.facebook.com/svard97";
              }}
              href="https://www.facebook.com/svard97"
              src={`${process.env.PUBLIC_URL}/facebook.png`}
              alt="Description"
            />
            <img
              className="footer__social__icon"
              onClick={(e) => {
                e.preventDefault();
                window.location =
                  "https://www.youtube.com/live/ZQBN5URy2uM?si=b3ZTMiJeSv5lo-zn";
              }}
              href="https://www.youtube.com/live/ZQBN5URy2uM?si=b3ZTMiJeSv5lo-zn"
              src={`${process.env.PUBLIC_URL}/youtube.png`}
              alt="Description"
            />
          </div>
        </div>
        <div>
          <h3>Kontakt</h3>
          <p>sam@samsvard.com </p>
          <p>+46 708-10 16 97</p>
        </div>
      </div>
      <p className="rights">All Rights Reserved © Samuel Svärd 2024</p>
      <div className="friend__success hidden">
        <h2>Tack {subscription.name?.split(" ")[0]}!</h2>
        <p>
          Samuel har fått ditt mail skickat från {subscription?.email}. Han
          återkommer så fort han har möjlighet, tills dess - andas.
        </p>
        <p>
          <strong>Intresserad av:</strong> "{subscription?.interest}"
        </p>
        <div
          onClick={(e) => {
            e.preventDefault();
            hideFriend("friend__success");
            window.location.reload();
          }}
          className="friend__cta"
        >
          <h4>Stäng</h4>
        </div>
      </div>
    </div>
  );
};

export default Footer;
