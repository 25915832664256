import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "../style/App.css";

const CheckoutForm = ({ purchase }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Dynamically set the return URL using window.location.origin
        return_url: `${window.location.origin}/${
          purchase === "package" ? "checkoutPackage" : "checkout"
        }`,
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  console.log("loading payment element!");
  return (
    <>
      <h2>Välj betalmetod</h2>
      <PaymentElement />
      <button disabled={!stripe} onClick={handleSubmit}>
        Bekräfta & betala
      </button>
    </>
  );
};

export default CheckoutForm;
