import React from "react";
import { Link } from "react-router-dom";
import Footer from "../modules/Footer";

export default function About() {
  return (
    <div className="about__container">
      <div className="about__hero">{/* <h1>"Hej!"</h1> */}</div>
      <div className="section__1__about">
        <h2>Om Samuel Svärd</h2>
        <p>
          Samuel arbetar sedan 2020 heltid som certifierad Breath Guide. Han har
          sedan dess blivit en av Sveriges mest eftertraktade facilitatörer inom
          området. Samuel är känd för sin trygga närvaro och förmåga att skapa
          en inkluderande atmosfär där deltagarna känner sig säkra innan de
          stegvis utmanas att lämna sin komfortzon. Samuel är också en mästare i
          att ha kul och använder humor och lättsinnighet i kombination med det
          djupa arbetet. Vem har sagt att inte arbete bara ska vara tungt och
          tråkigt? Utöver sitt arbete som breathwork-guide, är Samuel en
          passionerad musiker och röstkonstnär.
        </p>
      </div>

      <div className="section__1__about">
        <Link to="/retreat?retreat=1">
          <div className="cta">
            <h4>Boka retreat.</h4>
          </div>
        </Link>
      </div>
      <Footer />
    </div>
  );
}
