import React from "react";
import "../style/App.css";

const Policy = () => {
  return (
    <div className="policy-container">
      <h2>Avboknings- och Återbetalningspolicy</h2>
      <p>
        <strong>Datum:</strong> 2024-03-15
      </p>

      <h4>Allmän information</h4>
      <p>
        Denna policy gäller för alla tjänster och evenemang som tillhandahålls
        av Samuel Svärd, enskild firma, certifierad breathwork-instruktör,
        baserad i Solna, Stockholm. Alla tjänster är belagda med 6% moms.
      </p>

      <h4>Bokningsvillkor</h4>
      <p>
        Vid bokning av biljetter till Samuels retreats sker betalningen online
        genom de betalningsalternativ som erbjuds. Vid bekräftad betalning är
        platsen garanterad.
      </p>

      <h4>Avbokningsvillkor</h4>
      <p>
        För att säkerställa att alla deltagare har möjlighet att delta under
        bästa möjliga förutsättningar, tillämpas följande avbokningsregler:
      </p>
      <ul>
        <li>
          <strong>Avbokning 30 dagar eller mer före retreatets start:</strong>
          <p>
            Du får behålla biljetten till ett framtida retreat. Alternativt får
            du 50 % av pengarna tillbaka (minus Stripe's transaktionsavgift).
          </p>
        </li>
        <li>
          <strong>Avbokning 7-29 dagar före retreatets start:</strong>
          <p>
            Du får 50% (minus Stripe's transaktionsavgift) av biljettpriset
            tillbaka.
          </p>
        </li>
        <li>
          <strong>Avbokning inom 7 dagar före retreatets start:</strong>
          <p>Ingen återbetalning av biljettpriset.</p>
        </li>
      </ul>

      <h4>Orsak till strikt avbokningspolicy</h4>
      <p>
        Den strikta avbokningspolicyn är nödvändig på grund av den stora
        arbetsinsats som krävs för att planera och genomföra varje retreat.
        Eftersom biljetterna är dyra och platserna begränsade, blir det mycket
        svårt att fylla lediga platser tätt inpå eventet om någon avbokar.
      </p>

      <h4>Omplanering av evenemang</h4>
      <p>
        Om Samuel Svärd av någon anledning måste ändra datum eller ställa in ett
        retreat, kommer deltagarna att erbjudas full återbetalning eller
        möjlighet att delta i ett framtida retreat.
      </p>

      <h4>Kontaktinformation</h4>
      <p>
        För frågor eller ytterligare information, vänligen kontakta Samuel Svärd
        via e-post: <a href="mailto:sam@samsvard.com">sam@samsvard.com</a> eller
        telefon: +46 708-10 16 97.
      </p>

      <h4>Ändringar av policy</h4>
      <p>
        Denna policy kan komma att ändras vid behov. Alla ändringar kommer att
        meddelas i förväg och gälla för framtida bokningar.
      </p>
    </div>
  );
};

export default Policy;
