import React, { useEffect, useState, useRef } from "react";
import api from "./Api";
import "../style/App.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

const stripe = loadStripe(
  "pk_live_51O6TAlHLKd711KOaxzfRvkUTsgcWjjnw7XRejQz0HjrIuliLrcFK6O9KyRigrDdvu4lsT2RbZckYYzDXVNLiaDa000DGM7Q7Om"
);

export default function Retreat() {
  const [retreats, setRetreats] = useState([]);
  const [waitingPopup, setWaitingPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [clientSecret, setClientSecret] = useState();
  const [customer, setCustomer] = useState({
    name: "",
    email: "",
    tel: "",
    participants: "",
    policy: false,
  });
  const [news, setNews] = useState(false);
  const paymentE = useRef();
  const inputRef = useRef();

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!customer?.name) {
      formIsValid = false;
      errors["name"] = "Namn krävs.";
    }

    if (!customer?.email) {
      formIsValid = false;
      errors["email"] = "Email krävs.";
    }

    if (!customer?.tel) {
      formIsValid = false;
      errors["tel"] = "Tel krävs.";
    }

    if (!customer?.participants) {
      formIsValid = false;
      errors["participants"] = "Antal deltagare krävs.";
    }

    if (!customer?.policy) {
      formIsValid = false;
      errors["policy"] = "Du måste godkänna villkoren.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;

    // if name is policy, set it to the opposite of what it is
    if (name === "policy") {
      setCustomer((prevInfo) => ({
        ...prevInfo,
        [name]: !prevInfo[name],
      }));
    } else {
      setCustomer((prevInfo) => ({
        ...prevInfo,
        [name]: value,
      }));
    }
  };

  const handleNewsCheckbox = () => {
    setNews((prevInfo) => ({
      ...prevInfo,
      wantNews: !prevInfo.wantNews,
    }));
  };

  useEffect(() => {
    if (clientSecret && paymentE.current) {
      const elementRect = paymentE.current.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle =
        absoluteElementTop - window.innerHeight / 2 + elementRect.height / 2;
      window.scrollTo({ top: middle, behavior: "smooth" });
    }
  }, [clientSecret]);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function formatDateRange(starting_date, ending_date) {
    // Parse the dates
    const startDate = new Date(starting_date);
    const endDate = new Date(ending_date);

    // Define month and day formatters in Swedish
    const dayFormatter = new Intl.DateTimeFormat("sv", { weekday: "short" });
    const dateFormatter = new Intl.DateTimeFormat("sv", { day: "numeric" });
    const monthFormatter = new Intl.DateTimeFormat("sv", { month: "long" });

    // Extract the components
    const startDay = capitalizeFirstLetter(dayFormatter.format(startDate));
    const startDayNumber = dateFormatter.format(startDate);
    const endDay = capitalizeFirstLetter(dayFormatter.format(endDate));
    const endDayNumber = dateFormatter.format(endDate);
    const month = capitalizeFirstLetter(monthFormatter.format(startDate)); // assuming the same month for start and end

    // Build and return the string
    return `${startDay} ${startDayNumber} - ${endDay} ${endDayNumber} ${month}`;
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("retreat");

    const getRetreats = async () => {
      try {
        const response = await api.get("/retreats/one", { params: { id } });
        if (response.status !== 200) {
          console.log("error, status code not 200");
          return;
        }
        setRetreats(response.data.retreats);
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          console.error("Error in get Retreats", error.message);
        }
      } finally {
        setLoading(false);
      }
    };
    getRetreats();
  }, []);

  console.log(retreats);
  if (loading) {
    return <div>Loading...</div>;
  }

  const addToWaitingList = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await api.post("/waitinglist/create", {
        customer,
        retreats,
      });
      if (response.status !== 200) {
        console.log("Response status not 200");
        return;
      }
      setWaitingPopUp(true);
    } catch (error) {
      console.log("Some error creating paymentIntent", error.message);
    }
  };

  const piCreate = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      const response = await api.post("/pi/create", {
        customer,
        retreats,
      });
      if (response.status !== 200) {
        console.log("Response status not 200");
        return;
      }
      setClientSecret(response.data.clientSecret);
    } catch (error) {
      console.log("Some error creating paymentIntent", error.message);
    }
  };

  return (
    <div className="retreats__container__page">
      <div className="retreats__grid__page">
        {retreats?.map((val, key) => {
          const dateRange = formatDateRange(val.starting_date, val.ending_date);
          let availability;

          if (val.availability > 10) {
            availability = "Lediga platser";
          } else if (val.availability <= 10 && val.availability > 0) {
            // Explicitly including the equal to 10 condition here
            availability = "Få platser kvar";
          } else {
            availability = "Fullt";
          }

          console.log("availability", val.availability);
          return (
            <div className="retreats__card__page">
              <div className="img__frame__page">
                <img src={val.image} />
              </div>
              <div className="first__content">
                <h2>{val.name}</h2>
                <p>
                  <strong>📍 Home Tree</strong> | Mineralvägen 1, Österbybruk
                </p>
                <p className="date">{dateRange}</p>
                <p>
                  {" "}
                  <strong>{val.price} SEK/person*</strong>
                </p>
                <small>*6 stycken extrainsatta biljetter pga högt tryck.</small>
                <p className="availability">{availability}</p>
                <p className="availability">{val.availability} kvar</p>

                <div className="card__line"></div>
                <div className="container">
                  {Object.keys(errors).length > 0 && (
                    <div className="error-messages">
                      <ul>
                        {Object.values(errors).map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                      </ul>
                    </div>
                  )}
                  <ul>
                    <li>
                      <a
                        className="animated-arrow"
                        onClick={(e) => {
                          e.preventDefault();
                          // scroll to inputRef and make it land in the middle of the page
                          inputRef.current.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                          });
                        }}
                      >
                        <span className="the-arrow -left">
                          <span className="shaft"></span>
                        </span>
                        <span className="main">
                          <span className="text">
                            {val.availability > 0 ? "Boka nu" : "Väntelista"}
                          </span>
                          <span className="the-arrow -right">
                            <span className="shaft"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="second__content__page">
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: val.description }}
                />
                <div className="group" ref={inputRef}>
                  <input
                    className="subscription--name"
                    type="text"
                    name="name"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Namn</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="text"
                    name="email"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Email</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="text"
                    name="tel"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Tel</label>
                </div>
                <div className="group">
                  <input
                    className="subscription--name"
                    type="number"
                    min={1}
                    max={val.availability}
                    name="participants"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Antal</label>
                </div>
                <div className="group">
                  <textarea
                    className="subscription--interest"
                    type="text"
                    name="comment"
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                  <label>Kommentar</label>
                </div>
                <div className="checkbox__container">
                  <input
                    type="checkbox"
                    name="policy"
                    checked={customer?.policy}
                    required="required"
                    onChange={handleCustomerChange}
                  />
                  <label>
                    {/* open villkor in new tab */}
                    Jag godkänner{" "}
                    <a
                      style={{ color: "#fcdf2e", textDecoration: "underline" }}
                      href="/policy"
                      target="_blank"
                    >
                      villkoren
                    </a>
                    .
                  </label>
                </div>
                {customer?.participants && (
                  <div className="cart">
                    <h5>Din total:</h5>
                    {val.price * customer?.participants} SEK
                  </div>
                )}
                {!clientSecret && (
                  <div className="container">
                    {Object.keys(errors).length > 0 && (
                      <div className="error-messages">
                        <ul>
                          {Object.values(errors).map((error, index) => (
                            <p key={index}>{error}</p>
                          ))}
                        </ul>
                      </div>
                    )}
                    <ul>
                      <li>
                        <a
                          className="animated-arrow"
                          onClick={(e) => {
                            e.preventDefault();
                            if (val.availability > 0) {
                              piCreate();
                            } else {
                              addToWaitingList();
                            }
                          }}
                        >
                          <span className="the-arrow -left">
                            <span className="shaft"></span>
                          </span>
                          <span className="main">
                            <span className="text">
                              {val.availability > 0
                                ? "Välj betalmetod"
                                : "Väntelista"}
                            </span>
                            <span className="the-arrow -right">
                              <span className="shaft"></span>
                            </span>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {waitingPopup && (
          <div
            className="waiting__popup__overlay"
            onClick={() => setWaitingPopUp(false)}
          >
            <div className="waiting__popup__container">
              <div className="waiting__popup__content">
                <h2>Tack! Du är på väntelistan</h2>
                <p>
                  Blir det avhopp så går Samuel igenom väntelustan i turordning
                  och kontaktar dig ifall du är näst på tur! <br />
                  <br />
                  Undrar du något är det bara att kontakta Samuel på{" "}
                  <strong>sam@samsvard.com</strong> <br />
                  <br />
                  Until then - breathe!
                </p>
                <div className="container">
                  {Object.keys(errors).length > 0 && (
                    <div className="error-messages">
                      <ul>
                        {Object.values(errors).map((error, index) => (
                          <p key={index}>{error}</p>
                        ))}
                      </ul>
                    </div>
                  )}
                  <ul>
                    <li>
                      <a
                        className="animated-arrow"
                        onClick={(e) => {
                          // cause a page reload
                          window.location.reload();
                        }}
                      >
                        <span className="the-arrow -left">
                          <span className="shaft"></span>
                        </span>
                        <span className="main">
                          <span className="text">Stäng</span>
                          <span className="the-arrow -right">
                            <span className="shaft"></span>
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        {clientSecret && (
          <div className="stripe__form" ref={paymentE}>
            <Elements options={{ clientSecret }} stripe={stripe}>
              <CheckoutForm purchase={"retreat"} />
            </Elements>
          </div>
        )}
      </div>
    </div>
  );
}
