import { useState } from "react";
import "../style/App.css";

const ReadMoreReadLess = ({ children }) => {
  const [isReadMoreShown, setReadMoreShown] = useState(false);

  const toggleBtn = () => {
    setReadMoreShown((prevState) => !prevState);
  };
  return (
    <div className="read-more-read-less">
      <p className="description">
        {isReadMoreShown
          ? children?.substring(0, 600)
          : children?.substring(0, 200)}
        <button onClick={toggleBtn} className="read__more">
          {isReadMoreShown ? "... mindre" : "... mer"}
        </button>
      </p>
    </div>
  );
};

export default ReadMoreReadLess;
