import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../style/Navbar.css";

export default function NavBar({ children }) {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <div className="navbar__container">
        <Link className="samuel" to="/">
          Samuel Svärd
        </Link>
        <div onClick={handleToggle} className="hamburger-menu">
          <div
            className={`hamburger__bar  ${isActive ? "bar-rotate-45deg" : ""}`}
          ></div>
          <div
            className={`hamburger__bar  ${isActive ? "bar-rotate--45deg" : ""}`}
          ></div>
        </div>
        <div className={`navbar__menu ${isActive ? "active" : ""}`}>
          {/* <Link to="/retreats" onClick={handleToggle}>
            Retreats
          </Link> */}
          <Link to="/about" onClick={handleToggle}>
            Om Samuel
          </Link>

          {/* <a
            className="gratis__samtal"
            href="https://calendly.com/samsvard/free-call"
            target="_blank"
            rel="noopener noreferrer" // this is for security reasons
            onClick={handleToggle}
          >
            Gratis samtal
          </a> */}
          {/* <img
            onClick={(e) => {
              e.preventDefault();
              window.location =
                "https://open.spotify.com/playlist/55xLZsC6zWeUxK4W9njXBG?si=H6IgIzvCT32TNz8zhP7Agw&pi=e-zk1LzWDyQp2D";
            }}
            src={`${process.env.PUBLIC_URL}/Spotify_Icon_RGB_Green.png`}
            alt="Description"
          /> */}
        </div>
      </div>
      <div className="page__content">{children}</div>
    </>
  );
}
