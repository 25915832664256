import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "../style/App.css";
import Footer from "../modules/Footer";
import Retreats from "../modules/UpcomingRetreats";
import Packages from "../modules/Packages";

export default function Home() {
  const packagesRef = useRef();
  const scrollToPackages = () => {
    // Scrolls the 'onlineSession' div into view
    packagesRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div className="home__container">
      <div className="home__hero">
        <div className="hero__flow">
          <div className={`breathing-text`}></div>
        </div>
        <div
          className="cta"
          onClick={() => (window.location.href = "/retreat?retreat=1")}
        >
          <h4>Boka retreat.</h4>
        </div>
        <div className="hero__description">
          <h5>//// Samuel Svärd, Certified Breathwork Instructor</h5>
        </div>
      </div>
      {/* <div className="home__credentials">
        <div className="credential__2">
          <h3>
            "Samuel är en av de varmaste, klokaste och mest kärleksfulla
            Breathwork-ledarna jag upplevt."
          </h3>
          <p>- Navid Modiri</p>
        </div>
        <div className="credential__1">
          <h3>
            "Samuel är en otroligt inspirerande, lyhörd samt trygg och van
            instruktör/ breathguide."
          </h3>
          <p>- Cecilia Kårling</p>
        </div>

        <div className="credential__3">
          <h3>
            "Otroligt över min förväntan. Tveklöst en av Sveriges bästa
            breath-guides!"
          </h3>
          <p>- Oscar Sjöström</p>
        </div>
      </div>
      <div className="section__1">
        <div className="section__1--grid__1">
          <h2>Gör inte fel.</h2>
          <p className="section__1--quote">
            Breathwork är det mest effektiva verktyget för att snabbt minska
            stress, ångest, oro samtidigt som du ökar din livsglädje,
            kreativitet och energi. Om du inte får de resultaten efter första
            försöket får du garanterat det inom 5 sessioner. Annars gör du
            någonting fel.
          </p>
          <br />
          <p>
            Men oroa dig inte. Det är fysiskt omöjligt att inte få effekt om man
            följer tekniken rätt - och jag kommer guida dig dit // Samuel
          </p>
          <Link to="/about">
            <div className="cta">
              <h4>Lär känna mig.</h4>
            </div>
          </Link>
        </div>
        <div className="section__1--grid__2">
          <img
            src="https://res.cloudinary.com/dh8p6vqik/image/upload/v1699392952/section__1__img_xniyst.jpg"
            alt="Description"
          />
        </div>
      </div>
      <div ref={packagesRef} className="section__2">
        <img src={`${process.env.PUBLIC_URL}/test.png`} alt="Description" />
        <h2>GRATIS ONLINE SESSIONS</h2>
        <h4>Varje söndag klockan 16</h4>
        <div className="container">
          <ul>
            <li>
              <a
                className="animated-arrow"
                href="https://calendly.com/samsvard/bw"
              >
                <span className="the-arrow -left">
                  <span className="shaft"></span>
                </span>
                <span className="main">
                  <span className="text">Boka tid</span>
                  <span className="the-arrow -right">
                    <span className="shaft"></span>
                  </span>
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="section__2--centers">
          <div className="center__1">
            <h2>1</h2>
            <p>Fäll upp datorn eller telefonen i ett rum där du trivs.</p>
          </div>
          <div className="center__2">
            <h2>2</h2>
            <p>Gå in på videolänken i bekräftelse-mailet.</p>
          </div>
          <div className="center__3">
            <h2>3</h2>

            <p>Låt Samuel guida dig i din andning i 50 minuter.</p>
          </div>
        </div>
      </div>
      <div>
        <Packages />
      </div>
      {/* <Retreats /> */}
      {/* <Footer />  */}
    </div>
  );
}
