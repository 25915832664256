import React, { useState, useEffect } from "react";
import Api from "../modules/Api";
import Footer from "../modules/Footer";
import "../style/App.css";

export default function CheckoutPackage() {
  const [paymentI, setPaymentI] = useState();
  const urlString = window.location.search;
  const urlParams = new URLSearchParams(urlString);

  const pi = urlParams.get("payment_intent");

  useEffect(() => {
    capturePaymentIntent();

    // eslint-disable-next-line
  }, []);
  const capturePaymentIntent = async () => {
    try {
      const response = await Api.get("pi/fetch", {
        params: {
          paymentIntent: pi,
        },
      });
      if (response.status === 200) {
        console.log(response.data);
        setPaymentI(response.data);
      }
    } catch (error) {
      console.log("Error fetching pi");
    }
  };
  return (
    <div>
      <div className="retreats__page__container">
        <div className="search__div">
          <img
            className="retreats__hero"
            src={`${process.env.PUBLIC_URL}/samuel__hale.jpg`}
            alt="samuel background peace"
          />
          <div className="search__input">
            <h2>Tack för din bokning.</h2>
          </div>
        </div>
        {paymentI && (
          <div className="payment__details">
            <h4>Här är dina bokningsuppgifter</h4>
            <div className="payment__receipt">
              <p>
                <strong>Namn:</strong> {paymentI?.metadata?.customerName}
              </p>
              <p>
                <strong>Package:</strong> {paymentI?.metadata?.package}
              </p>
              <p>
                <strong>Antal:</strong> {paymentI?.metadata?.participants}
              </p>
              <p>
                <strong>Totalt Pris:</strong> {paymentI?.amount / 100} SEK
              </p>
            </div>
            <h4>Kvitto skickas till {paymentI?.receipt_email}</h4>
            <p>
              Samuel kommer att höra av sig så fort han blivit informerad om
              ditt köp.
            </p>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
}
